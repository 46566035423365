<template>
  <BackofficeBase>
    <h1>SIMPO ברוכים הבאים למערכת האדמין של</h1>

    <div v-if="filteredData.length" align="center">
      <h2>נקודות חלוקה עם תפוסה מעל 80%</h2>
      <table border="1" dir="rtl">
        <thead>
          <tr>
            <th>שם נקודת חלוקה</th>
            <th>אימייל</th>
            <th>אחוז תפוסה</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="point in filteredData" :key="point.distribution_point_id">
            <td>{{ point.distribution_point_name }}</td>
            <td>{{ point.email }}</td>
            <td>{{ point.occupancy_percentage }}%</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else align="center">
      <p>אין נקודות חלוקה עם תפוסה מעל 80%.</p>
    </div>



  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
export default {
  name: "Backoffice",
  components: {
    BackofficeBase,
  },
  data() {
    return {
      data: [],
    };
  },
  async mounted() {
    let backendModel = new BackendModel();
    let data = await backendModel.backendRequest(
      "/Api/service/backoffice/get_distribution_point_status",{}
    );
    this.data = data.data.data || [];
    console.log(this.data);
  },
  computed: {
    filteredData() {
      return this.data.filter(point => parseFloat(point.occupancy_percentage) > 80);
    },

  },
};
</script>
